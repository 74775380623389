<template>
    <div class="vx-col w-full">
        <vx-card>
            <div class="table-title">
                <h2>{{tableTitle}}</h2>                
            </div>
            <div class="common-table">      
                <div class="table-row-select">
                    <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in recordList" />
                    </vs-select>
                </div>
                <vs-table :data="tableRecords" ref="tableData" :max-items="perPage" pagination search>
                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th v-for="(columnName, index) in columnNameArray" :key="index" :sort-key="columnSortKeyArray[index]">{{columnName}}</vs-th>
                        <!-- <vs-th sort-key="Page_Count">Entrances</vs-th>
                        <vs-th sort-key="sortablePagePercent">Enter %</vs-th>
                        <vs-th sort-key="Bounce_Count">Bounces</vs-th>
                        <vs-th sort-key="sortableBouncePercent">Bounce %</vs-th>
                        <vs-th sort-key="sortableBounceRatePercent">Bounce Rate</vs-th> -->
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td width="50">
                                <vs-button :title="LabelConstant.buttonLabelViewInformation" @click="$emit('clickToGetDetailData', entryPageOrExitPage, tr.Page)" type="filled" icon-pack="feather" icon="icon-search" class="mr-0" color="primary"></vs-button>
                            </vs-td>
                            <vs-td :data="tr.Page">{{tr.Page}}</vs-td>
                            <vs-td :data="tr.Page_Count">{{tr.Page_Count}}</vs-td>
                            <vs-td :data="tr.Page_Percent">{{tr.Page_Percent}}</vs-td>
                            <vs-td :data="tr.Bounce_Count">{{tr.Bounce_Count}}</vs-td>
                            <vs-td :data="tr.Bounce_Percent">{{tr.Bounce_Percent}}</vs-td>
                            <vs-td :data="tr.Bounce_Rate_Percent">{{tr.Bounce_Rate_Percent}}</vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{tableRecords.length|tableRecordNumberFormatter}} entries</p>
                <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{tableRecords.length|tableRecordNumberFormatter}} entries)</p>
                <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
            </div>
        </vx-card>
    </div>
</template>

<script>

export default ({
    mounted(){
        this.$emit('refDataInput', this.$refs.tableData)
    },
    props:{
        tableTitle:{
            required: true
        },
        perPageValue:{
            required: true
        },
        recordList:{
            required: true
        },
        tableRecords:{
            required: true
        },
        columnNameArray:{
            required: true
        },
        columnSortKeyArray:{
            required: true
        },
        entryPageOrExitPage:{
            required: true
        }
    },
    computed: {
        perPage: {
            get() { return this.perPageValue },
            set(perPageValue) { this.$emit('perPageInput', perPageValue) }
        },
        /**
		 * Showing Entries details
		 * isTableRecordEntriesDisplay()
		 * isSearch()
		 * showingDataFrom()
		 * showingDataTo()
		 * totalSearchedData()
         * showingSearchedDataTo()
		 *  */
        isTableRecordEntriesDisplay() {
            if (this.tableRecords.length > 0) {
                if (this.$refs.tableData.datax.length > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        isSearch: function () {
            if (this.tableRecords.length > 0) {
                if (this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == "") {
                    return false
                } else {
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if (this.tableRecords.length > 0) {
                return ((this.$refs.tableData.currentx - 1) * this.perPage) + 1
            }
        },
        showingDataTo: function () {
            if (this.tableRecords.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPages
                if (totalPages == this.$refs.tableData.currentx) {
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                } else {
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        },
        totalSearchedData: function () {
            if (this.tableRecords.length > 0) {
                return this.$refs.tableData.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if (this.tableRecords.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPagesSearch
                if (totalPages == this.$refs.tableData.currentx) {
                    return ((this.$refs.tableData.currentx - 1) * this.perPage) + this.$refs.tableData.datax.length
                } else {
                    return ((this.$refs.tableData.currentx) * this.perPage)
                }
            }
        }
    }
})
</script>
